<template>
  <div class="resource-page">
    <ParkSelection @getPark="getParkIndex"></ParkSelection>
    <div class="resource-title">客户</div>
    <div class="connection-box">
      <div class="connection-list" v-for="(item, index) in data" :key="index">
        <div class="connection-border-top">
          <div class="list-title">{{ item.title }}</div>
          <div class="humanNum" v-if="item.num">{{ item.num }}</div>
          <div class="line"></div>
          <div class="detail-box" v-if="item.type == 0">
            <div
              class="detail-list"
              v-for="(item_d, index) in item.list"
              :key="index"
            >
              <div class="corport">{{ item_d.corporation }}</div>
              <div class="date">{{ item_d.date }}</div>
            </div>
          </div>
          <div>
            <div class="progress-box" v-if="item.type == 1">
              <div
                class="progress-list"
                v-for="(item_d, index) in item.list"
                :key="index"
              >
                <div class="name">{{ item_d.corporation }}</div>
                <div class="assembly">
                  <el-progress
                    :percentage="item_d.propor"
                    :stroke-width="14"
                    :show-text="false"
                    color="#1A4CEC"
                    define-back-color="#FFFFFF"
                  ></el-progress>
                </div>
                <div :class="index < 3 ? 'newlyNum' : 'lastNum'">
                  {{ item_d.propor }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="connection-border-bottom">
          <div class="contact-time">{{ item.contactTime }}</div>
          <div class="contact-day">{{ item.contactDay }}</div>
        </div>
      </div>
    </div>
    <QuickLink :linkList="linkData"> </QuickLink>
  </div>
</template>

<script>
import QuickLink from "../../components/QuickLink/index.vue";
import ParkSelection from "../../components/ParkSelection/index.vue";
export default {
  components: {
    ParkSelection,
    QuickLink,
  },
  data() {
    return {
      data: [
        {
          title: "本月新增客户",
          num: "26",
          contactDay: "78",
          contactTime: "超过7天未联系",
          type: "0",
          list: [
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
          ],
        },
        {
          title: "本月新增客户",
          num: "",
          contactDay: "78",
          contactTime: "超过15天未联系",
          type: "1",
          list: [
            { corporation: "李达凯", propor: 80 },
            { corporation: "徐梓平", propor: 70 },
            { corporation: "杜婷奇", propor: 60 },
            { corporation: "傅浩林", propor: 40 },
            { corporation: "傅浩林", propor: 20 },
          ],
        },
        {
          title: "本月新增客户",
          num: "26",
          contactDay: "78",
          contactTime: "超过30天未联系",
          type: "0",
          list: [
            { corporation: "陈先声", date: "2022-12-12" },
            { corporation: "李小杰", date: "2022-12-12" },
            { corporation: "张零", date: "2022-12-12" },
            { corporation: "封调宇", date: "2022-12-12" },
            { corporation: "张骄浇", date: "2022-12-12" },
          ],
        },
        {
          title: "本月新增客户",
          num: "26",
          contactDay: "78",
          contactTime: "超过3个月未联系",
          type: "0",
          list: [
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
            { corporation: "深圳市丽康宁公司", date: "2022-12-12" },
          ],
        },
        {
          title: "本月新增客户",
          num: "26",
          contactDay: "78",
          contactTime: "超过6个月天未联系",
          type: "0",
          list: [
            { corporation: "深圳市丽康宁公司", date: "3次" },
            { corporation: "深圳市丽康宁公司", date: "1次" },
            { corporation: "深圳市丽康宁公司", date: "0次" },
            { corporation: "深圳市丽康宁公司", date: "1次" },
            { corporation: "深圳市丽康宁公司", date: "2次" },
          ],
        },
      ],
      linkData: [
        {
          title: "CRM 仪表盘",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/crm/workbench"),
        },
        {
          title: "CRM 线索",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/crm/leads"),
        },
        {
          title: "CRM 联系人",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/crm/contacts"),
        },
        {
          title: "CRM BI",
          iconPathOff: require("@/assets/images/bms/link_set.png"),
          iconPathOn: require("@/assets/images/bms/link_set_wh.png"),
          urlType: true,
          linkUrl: this.crmLinkRender("/bi/customer/total"),
        },
      ],
    };
  },

  methods: {
    crmLinkRender(path) {
      const pathMap = {
        DEV: "https://test-crm.rlinkiot.com/#",
        SIT: "https://test-crm.rlinkiot.com/#",
        UAT: "https://test-crm.rlinkiot.com/#",
        PROD: "https://crm.rlinkiot.com/#",
      };
      return `${pathMap[process.env.VUE_APP_ENV]}${path}?auth-token=${
        this.$store.getters.baseAuthToken
      }`;
    },
    getParkIndex(index, projectId) {
      console.log("切换", index, projectId);
    },
    getData() {},
    changePark(index) {
      this.parkIndex = index;
    },
  },
};
</script>

<style  lang="less" scoped>
.resource-page {
  padding: 32px 16px 32px 40px;

  .resource-title {
    margin-top: 40px;
    height: 20px;
    font-size: 20px;
    font-family: Inter-Semi Bold, Inter;
    font-weight: bold;
    color: #000000;
    line-height: 20px;
  }
  .connection-box {
    display: flex;
    flex-wrap: wrap;
    .connection-list {
      height: 511px;
      margin-top: 16px;
      .connection-border-top {
        margin-bottom: 16px;
        height: 365px;
        margin-right: 16px;
        padding: 24px 24px 16px 24px;
        width: 305px;
        height: 365px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ebeef5;
        .list-title {
          margin-left: 28px;
          height: 24px;
          font-size: 16px;
          font-family: Noto Sans SC-Bold, Noto Sans SC;
          font-weight: bold;
          color: #3b454c;
          line-height: 24px;
        }
        .humanNum {
          margin-top: 16px;
          height: 40px;
          font-size: 40px;
          font-family: Noto Sans SC-Bold, Noto Sans SC;
          font-weight: bold;
          color: #3b454c;
          line-height: 40px;
        }
        .line {
          margin-top: 24px;
          height: 1px;
          background: #ebeef5;
          width: 100%;
        }
        .detail-box {
          padding-top: 4px;

          .detail-list {
            margin-top: 12px;
            height: 20px;
            line-height: 20px;
            .corport {
              float: left;
              font-size: 14px;
              font-family: Noto Sans SC-Bold, Noto Sans SC;
              font-weight: bold;
              color: #1a4cec;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 160px;
            }
            .date {
              float: right;
              font-size: 14px;
              font-family: Noto Sans SC-Regular, Noto Sans SC;
              font-weight: 400;
              color: #9099a9;
            }
          }
        }
        .progress-box {
          padding-top: 16px;
          .progress-list {
            display: flex;
            margin-bottom: 20px;
            height: 20px;
            .name {
              width: 40px;
              height: 16px;
              line-height: 16px;
              font-size: 12px;
              font-family: Noto Sans SC-Medium, Noto Sans SC;
              font-weight: 500;
              color: #8993a4;
              margin-right: 8px;
            }
            .assembly {
              width: 190px;
              margin-right: 10px;
              margin-top: 2px;
            }
            .newlyNum {
              width: 18px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              background: #effcfc;
              border-radius: 4px 4px 4px 4px;
              color: #5fdddc;
              font-size: 12px;
            }
            .lastNum {
              width: 18px;
              height: 18px;
              line-height: 18px;
              text-align: center;
              border-radius: 4px 4px 4px 4px;
              color: #8993a4;
              font-size: 12px;
            }
          }
        }
      }
      .connection-border-bottom {
        width: 305px;
        height: 130px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ebeef5;
        .contact-time {
          margin: 16px 0px 26px 24px;
          height: 24px;
          font-size: 16px;
          font-family: Noto Sans SC-Medium, Noto Sans SC;
          font-weight: 500;
          color: #2f3941;
          line-height: 24px;
        }
        .contact-day {
          margin-left: 24px;
          height: 40px;
          font-size: 40px;
          font-family: Noto Sans SC-Bold, Noto Sans SC;
          font-weight: bold;
          color: #3b454c;
          line-height: 40px;
        }
      }
    }
  }
}
</style>